import React, { useEffect, useState, useContext } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { UrlContext } from '../../context/UrlContext';

function ApiPoolOccupancy() {
    const [fetchedData, setFetchedData] = useState([]);
    const ServerUrl = useContext(UrlContext);

    useEffect(() => {
      let ClubToCheck = sessionStorage.getItem('selectedClub');
      fetch(`${ServerUrl}/api_PoolOccupancy?pCLUB_CODE=${ClubToCheck}`)
        .then(response => response.json())
        .then(data => setFetchedData(data.TIME_DATA_LIST));
    },[ServerUrl]);

  return (
    <div className="PoolOccupancyContent">     
      <ResponsiveContainer height={400}>
        <LineChart
          data={fetchedData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="HOUR" />
          <YAxis />
          <Tooltip />
          <Legend />
          
          <Line dataKey="TOTAL_ASSISTANTS" name="TOTAL ASSISTANTS" fill="#043bd7" stroke="#043bd7" />
        </LineChart>
      </ResponsiveContainer>    
    </div>
  )
}

export default ApiPoolOccupancy
import { useState,useEffect, useContext } from "react";
import ApiActivitiesOccupancy from '../apiAccess/ApiActivitiesOccupancy';
import { UrlContext } from "../../context/UrlContext";
import { useLanguage } from "../../context/LanguageContext";
import "../../assets/styles/loading.css";

function ActivitiesOccupancy() {

  const [fetchedCode, setFetchedCode] = useState([]);
  const [fetchedMsg, setFetchedMsg] = useState([]);
  const [fetchedTitle, setFetchedTitle] = useState([]);
  const [fetchedClub, setFetchedClub] = useState([]);
  const [fetchedDates, setFetchedDates] = useState([]);
  const [fetchedCriteria, setFetchedCriteria] = useState([]);
  const ServerUrl = useContext(UrlContext);
  const { /* language, */ translations } = useLanguage();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let ClubToCheck = sessionStorage.getItem('selectedClub');
    fetch(`${ServerUrl}/api_ActivitiesOccupancy?pCLUB_CODE=${ClubToCheck}`)
    .then(response => response.json())
    .then(data => {
      const { MESSAGE_CODE, MESSAGE, PAGE_TITLE, CLUB_NAME, RANGE_OF_DATES, OCCUPATION_CRITERIA } = data;
      setFetchedCode(MESSAGE_CODE);
      setFetchedMsg(MESSAGE);
      setFetchedTitle(PAGE_TITLE);
      setFetchedClub(CLUB_NAME);
      setFetchedDates(RANGE_OF_DATES);
      setFetchedCriteria(OCCUPATION_CRITERIA);
      setIsLoading(false);
    });  
  }, [ServerUrl]);  

  if (isLoading) {
    return <div className="loading"><p>{translations.loading}</p></div>;
  }
  else if ( fetchedCode === 0 ) {
  return (
    <div>
      <div className="pageTitle">
        <h1>{fetchedTitle} <span className="CriteriaType">{fetchedCriteria}</span></h1>
        <p className="ClubName">{fetchedClub}</p>
        <p className="DatesRange">{fetchedDates}</p>
      </div>  
        <div className='sign activities'>
          <p><button className='red'> &lt;30% d'ocupació </button></p>
          <p><button className='yellow'> 30-69% ocupació </button></p>
          <p><button className='green'> &gt;70% ocupació </button></p>
        </div>
        <ApiActivitiesOccupancy />
    </div>
  )
  }

  else if ( fetchedCode !== "0" ) {
    return (
    <>
    <h4>{fetchedMsg}</h4>
    </>
    )
  }
}

export default ActivitiesOccupancy
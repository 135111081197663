import { useState, useEffect, useContext } from "react";
import ApiGender from "./apiAccess/ApiGender";
import { UrlContext } from "../context/UrlContext";
import { useLanguage } from "../context/LanguageContext";
import "../assets/styles/loading.css";
import useStorage from "../hooks/useStorage";

function AccessByGender() {
  const [fetchedCode, setFetchedCode] = useState([]);
  const [fetchedMsg, setFetchedMsg] = useState([]);
  const [fetchedTitle, setFetchedTitle] = useState([]);
  const [fetchedClub, setFetchedClub] = useState([]);
  const [fetchedDates, setFetchedDates] = useState([]);
  const [fetchedMembership, setFetchedMembership] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(0); // ⚡ Nouvelle variable pour recharger instantanément
  const ServerUrl = useContext(UrlContext);
  const { translations } = useLanguage();
  const storage = useStorage()

  const fetchData = () => {

    if (!storage) return;
console.log(storage);
let ClubToCheck = sessionStorage.getItem('selectedClub');
    let StartDateToCheck = storage.getItem("startDate");
    let EndDateToCheck = storage.getItem("endDate");
    const LangToCheck = localStorage.getItem("language");
    const storedClub = storage.getItem("storedClubs");
  
    // Si `selectedClub` n'existe pas, on vérifie dans `storedClubs`
   /* if (!ClubToCheck && storedClub) {
      try {
        const parsedStoredClub = JSON.parse(storedClub);
        if (Array.isArray(parsedStoredClub) && parsedStoredClub.length > 0) {
          ClubToCheck = parsedStoredClub[0].CLUB_CODE;
        } else {
          console.log("storedClubs est vide ou incorrect.");
        }
      } catch (error) {
        console.error("Erreur de parsing JSON pour storedClubs:", error);
      }
    }
  
    // Vérifier si `ClubToCheck` est bien défini avant d'envoyer la requête
    if (!ClubToCheck) {
      console.error("Aucun club sélectionné pour la requête.");
      return;
    }*/
  

console.log('eee',ClubToCheck)
    setIsLoading(true);
    fetch(`${ServerUrl}/api_AccessByGender?pCLUB_CODE=${ClubToCheck}`)
      .then(response => response.json())
      .then(data => {
        const { MESSAGE_CODE, MESSAGE, PAGE_TITLE, CLUB_NAME, RANGE_OF_DATES, TOTAL_ACTIVE_MEMBERSHIP } = data;
        setFetchedCode(MESSAGE_CODE);
        setFetchedMsg(MESSAGE);
        setFetchedTitle(PAGE_TITLE);
        setFetchedClub(CLUB_NAME);
        setFetchedDates(RANGE_OF_DATES);
        setFetchedMembership(TOTAL_ACTIVE_MEMBERSHIP);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!storage) return;
    console.log(storage);
    let ClubToCheck = storage.getItem('selectedClub');
        let StartDateToCheck = storage.getItem("startDate");
        let EndDateToCheck = storage.getItem("endDate");
        const LangToCheck = localStorage.getItem("language");
        const storedClub = storage.getItem("storedClubs");
      
        // Si `selectedClub` n'existe pas, on vérifie dans `storedClubs`
       /* if (!ClubToCheck && storedClub) {
          try {
            const parsedStoredClub = JSON.parse(storedClub);
            if (Array.isArray(parsedStoredClub) && parsedStoredClub.length > 0) {
              ClubToCheck = parsedStoredClub[0].CLUB_CODE;
            } else {
              console.log("storedClubs est vide ou incorrect.");
            }
          } catch (error) {
            console.error("Erreur de parsing JSON pour storedClubs:", error);
          }
        }
      
        // Vérifier si `ClubToCheck` est bien défini avant d'envoyer la requête
        if (!ClubToCheck) {
          console.error("Aucun club sélectionné pour la requête.");
          return;
        }*/
      
    
    console.log('eee',ClubToCheck)
        setIsLoading(true);
        fetch(`${ServerUrl}/api_AccessByGender?pCLUB_CODE=${ClubToCheck}`)
          .then(response => response.json())
          .then(data => {
            const { MESSAGE_CODE, MESSAGE, PAGE_TITLE, CLUB_NAME, RANGE_OF_DATES, TOTAL_ACTIVE_MEMBERSHIP } = data;
            setFetchedCode(MESSAGE_CODE);
            setFetchedMsg(MESSAGE);
            setFetchedTitle(PAGE_TITLE);
            setFetchedClub(CLUB_NAME);
            setFetchedDates(RANGE_OF_DATES);
            setFetchedMembership(TOTAL_ACTIVE_MEMBERSHIP);
            setIsLoading(false);
          });
  }, [ServerUrl]); // 🔥 `reload` déclenche un appel instantané

 /* if (isLoading) {
    return <div className="loading"><p>{translations.loading}</p></div>;
  }*/

  return (
    <div>
      <div className="pageTitle">
        <h1>{fetchedTitle}</h1>
        <p className="ClubName">{fetchedClub}</p>
        <p className="DatesRange">{fetchedDates}</p>
        <p className="TotalMembership">Nombre de clients: {fetchedMembership}</p>
      </div>
      
      <button onClick={() => setReload(prev => prev + 1)} className="reloadButton">
        🔄 Recharger
      </button>

      {/*{fetchedCode === '0' ? <ApiGender /> : <h4>{fetchedMsg}</h4>}*/}
      <ApiGender /> 
    </div>
  );
}

export default AccessByGender;


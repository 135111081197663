import React, { useEffect, useState, useContext } from "react";
import { UrlContext } from "../../context/UrlContext";

function ApiActivitiesOccupancy() {
    const [fetchedData, setFetchedData] = useState([]);
    const ServerUrl = useContext(UrlContext);
    
    useEffect(() => {
      let ClubToCheck = sessionStorage.getItem('selectedClub');
      fetch(`${ServerUrl}/api_ActivitiesOccupancy?pCLUB_CODE=${ClubToCheck}`)
        .then(response => response.json())
        .then(data => setFetchedData(data.ACTIVITIES_DATA_LIST));
    },[ServerUrl]);
    
    const uniqueDaysOfWeek = [...new Set(fetchedData.map(item => item.DAY_OF_WEEK))];
    const sortedDaysOfWeek = uniqueDaysOfWeek.sort((a, b) => a - b);

    const uniqueTimeFrom = [...new Set(fetchedData.map(item => item.TIME_FROM))];

    const getActivityColorClass = occupationColor => {
        switch (occupationColor) {
          case 1:
            return 'green';
          case 2:
            return 'yellow';
          case 3:
            return 'red';
          default:
            return '';
        }
      };

  return (
    <div className="Container">
        <div className="ActivitiesContainer">
          <table border={1} className="ActivitiesTable">
            <thead>
                <tr>
                    <th className="TimeTitle"> </th>
                    {sortedDaysOfWeek.map(dayOfWeek => {
                    const day = fetchedData.find(item => item.DAY_OF_WEEK === dayOfWeek);
                      return (
                        <th key={dayOfWeek}>{day.DAY_OF_WEEK_NAME}</th>
                      );
                    })}
                </tr>
            </thead>

<tbody>
  {uniqueTimeFrom.map(timeFrom => (
    <tr key={timeFrom}>
      <th>{timeFrom}</th>
      {sortedDaysOfWeek.map(dayOfWeek => {
        const activities = fetchedData.filter(item => item.TIME_FROM === timeFrom && item.DAY_OF_WEEK === dayOfWeek);
        const colorClasses = activities.map(activity => getActivityColorClass(activity.OCCUPATION_COLOR));
        const activityNames = activities.map(activity => activity.ACTIVITY_NAME);
        const CapacityTarget = activities.map(activity => activity.CAPACITY_TARGET);
        const TotalAssistants = activities.map(activity => activity.TOTAL_ASSISTANTS);
        const Percent = activities.map(activity => activity.PERCENT);


        return (
          <td key={dayOfWeek}>
            {activityNames.map((activityName, index) => (
              <div key={index} className={colorClasses[index]}>
                {activityName}
                <p>{CapacityTarget[index]} / {TotalAssistants[index]} ({Percent[index]} %)</p>
              </div>
            ))}
          </td>
        );
      })}
    </tr>
  ))}
</tbody>
          </table>
        </div>        
    </div>
  )
}

export default ApiActivitiesOccupancy
import React, { useState, useContext, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ImgLogin from "../assets/images/logo-eurofitness-login.png";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { UrlContext } from '../context/UrlContext';
import { FormControlLabel, Checkbox, NativeSelect } from '@mui/material';
import { Language, LocalDining, Translate } from '@material-ui/icons';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${ImgLogin})`,
    backgroundSize: 'cover',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function LoginForm() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [username, setUserName] = useState(null);
  const [password, setPassword] = useState(null);
  const [errors, setErrors] = useState([]);
  const [rememberMe, setRememberMe] =useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedLang, setSelectedLang] = useState('CAT');
  const [messageStored, setStoredMessage] = useState('');
 
  const [langForm,setLangForm] = useState(["Log In","User Name","Password","Remember me","Sign In"])
  const [langFormErrors,setLangFormErrors] = useState(["You must indicate the required data","Name required","Password required"])

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const ServerUrl = useContext(UrlContext);



  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedLang(value);
    localStorage.setItem('language', value);
    Translate()
  }

  const handleRemember = (event) => {
    console.log(event)
    setRememberMe(event)
  //  if (!event) {
  //   localStorage.clear();
  //  }
   localStorage.setItem('remember', event);

  //window.location.href = "/";

   
   
  };


  function Translate() {
    let value = localStorage.getItem('language')
    
    switch(value) {
      case 'ESP':   
        setLangForm(["Inicio de sesión","Usuario","Contraseña","Recordar","Iniciar sesión"])     
        setLangFormErrors(["Debes indicar los datos requeridos", "Nombre requerido", "Contraseña requerida"]) 
        break;
      case 'FRA':
        setLangForm(["Connexion","Utilisateur","Mot de passe","Se souvenir","Se connecter"]) 
        setLangFormErrors(["Vous devez indiquer les données requises", "Nom requis", "Mot de passe requis"])
        break;
      case 'ENG':
        setLangForm(["Log In","User Name","Password","Remember me","Sign In"]) 
        setLangFormErrors(["You must indicate the required data","Name required","Password required"]) 
        break;
      case 'POL':
        setLangForm(["Logowanie","Użytkownik","Hasło","Zapamiętaj","Zaloguj się"]) 
        setLangFormErrors(["Musisz podać wymagane dane", "Wymagane imię", "Wymagane hasło"] )
        break;
      case 'SLV':
        setLangForm(["Prijava","Uporabniško ime","Geslo","Zapomni si","Prijava"]) 
        setLangFormErrors(["Musíte uviesť požadované údaje", "Požadované meno", "Požadované heslo"])
        break;
      default:
        setLangForm(["Inici de sessió","Usuari","Contrasenya","Recorda'm","Inicia sessió"]) 
        setLangFormErrors(["Has d'indicar les dades requerides", "Nom requerit", "Contrasenya requerida"])
        break;      
    }
    return true;
  }

  useEffect(() => {

    if (loading === true) {

      let lang = localStorage.getItem('language');
      if (lang === null) lang = 'CAT';
      console.log("lang=", lang)
      setSelectedLang(lang);
      Translate();
      const storageType = localStorage.getItem('storage')
      const storage = storageType === 'local' ? localStorage : sessionStorage;
      let curUser = storage.getItem('storedUsername');
      let curPass = storage.getItem('storedPassword');
      let curLang = localStorage.getItem('language');
      let curRemember = storage.getItem('remember');
      let storedName = storage.getItem('storedName');
      let storedClubs = storage.getItem('storedClubs');
      let storedAccess = storage.getItem('storedAccess');
      let storedMessage = storage.getItem('storedMessage');

      if (curUser == null || curUser == undefined) curUser = ""
      if (curPass == null || curPass == undefined) curPass = ""
      if (curLang == null || curLang == undefined) curLang = "CAT"
      if (curRemember == null || curRemember == undefined) curRemember = false
      if (storedName == null || storedName == undefined) storedName = ""
      if (storedClubs == null || storedClubs == undefined) storedClubs = ""
      if (storedAccess == null || storedAccess == undefined) storedAccess = ""
      if (storedMessage == null || storedMessage == undefined) storedMessage = ""



      setStoredMessage(storedMessage)

      setLoading(false)
    }
    
   

   

  });


  
  async function loginUserAndFetchData() {
    try {
    const loginResponse = await fetch(`${ServerUrl}/SaveUser`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ User: username, Password: password, Lang: selectedLang })
    }).then(data => data.json());

    const fetchResponse = await fetch(`${ServerUrl}/api_Login`)
      .then(response2 => response2.json());
    
    let userlogin = {
      username: username,
      password: password,
      storedName: fetchResponse.NAME,
      storedCode: JSON.stringify(fetchResponse.MESSAGE_CODE),
      storedMessage: JSON.stringify(fetchResponse.MESSAGE).replace(/"/g, ''),
      storedClubs: fetchResponse.CLUB_LIST,
     
      storedAccess: fetchResponse.FEATURE_GRANTS_LIST,
      userlogged: false,
      rememberMe: false,
      language: selectedLang
    }
    const defaultClubCode = userlogin.storedClubs[0].CLUB_CODE; 
    sessionStorage.setItem("selectedClub", defaultClubCode);
    if (userlogin.storedCode !== "0") {

      if (userlogin.storedMessage !== "") {

        setStoredMessage(userlogin.storedMessage)

      } else {

        setStoredMessage("Credentials not found")

      }

    } else {

      try {

        if (userlogin.storedClubs.length > 0) {

           userlogin.userlogged = true
           userlogin.rememberMe = rememberMe
           const storage = userlogin.rememberMe ? localStorage : sessionStorage;
           localStorage.setItem('storage',userlogin.rememberMe ? 'local' : 'session')
      
           console.log(storage)
           storage.setItem('storedName', userlogin.storedName);
           storage.setItem('storedClubs', JSON.stringify(userlogin.storedClubs));
           const defaultClubCode = userlogin.storedClubs[0].CLUB_CODE; // Récupère le premier club
 // sessionStorage.setItem("selectedClub", defaultClubCode);
  storage.setItem("selectedClub", defaultClubCode);
           storage.setItem('storedAccess', JSON.stringify(userlogin.storedAccess));
           storage.setItem('remember', userlogin.rememberMe);
           storage.setItem('storedUsername', userlogin.username);
           storage.setItem('storedPassword', userlogin.password);
           storage.setItem('storedLanguage', userlogin.language);

        } else {
          setStoredMessage("No access clubs")
        }

      } catch {
        setStoredMessage("No access clubs")
      }

      
    }

    return { loginResponse, fetchResponse };
  } catch (error) {
    throw new Error('An error occurred during login and data fetch.');
  }
  }

  const handleSubmit = async e => {
    e.preventDefault();

    const validationErrors = [];

    setErrors([]);

    if (!username) validationErrors.push(langFormErrors[1]);
    if (!password) validationErrors.push(langFormErrors[2]);

    if (validationErrors.length > 0) {
      setStoredMessage(langFormErrors[0])
      setErrors(validationErrors);
      return;
    }

    try {
      const { loginResponse, fetchResponse } = await loginUserAndFetchData();
      console.log("msg=")
      if(fetchResponse.MESSAGE_CODE !== 0) {
        setStoredMessage(fetchResponse.MESSAGE);
        return;
      }
      window.location.reload();
    } catch (error) {
      setErrors(['An error occurred during login and data fetch.']);
      setStoredMessage("An error occurred during login and data fetch.");
    }
  };

  if (loading === true) {
    return (<>Loading page...</>)
  }

  return (
    <Grid container className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} md={7} className={classes.image} />
      <Grid item xs={12} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {langForm[0]}
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField defaultValue={username}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              name="username"
              label={langForm[0]}
              onChange={e => setUserName(e.target.value)}
            />
            <TextField defaultValue={password}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label={langForm[2]}
              type={showPassword ? "text" : "password"}
              onChange={e => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

          <Typography  variant="body2" color="error" >
            {messageStored}
          </Typography>


            {errors.map((error, index) => (
              <Typography key={index} variant="body2" color="error">
                {error}
              </Typography>
            ))}            
            <FormControlLabel
              control={
                <Checkbox style={{marginLeft:'10px'}}
                  checked={rememberMe}
                  //onChange={(e) => setRememberMe(e.target.checked)}
                  onChange={(e) => handleRemember(e.target.checked)}
                  color="primary"
                />
              }
              label={langForm[3]}
            />
            <NativeSelect
            defaultValue={selectedLang}
            onChange={handleSelectChange}
            inputProps={{
              name: 'language',
              id: 'uncontrolled-native',
            }}
            >
              <option value='CAT'>Català</option>
              <option value='ESP'>Español</option>
              <option value='FRA'>Français</option>
              <option value='ENG'>English</option>
              <option value='POL'>Polski</option>
              <option value='SLV'>Slovenščina</option>
            </NativeSelect>                    
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
             {langForm[4]}
            </Button>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
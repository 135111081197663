import { useState, useContext, useEffect } from "react";
import { UrlContext } from '../context/UrlContext';
import { useLanguage } from "../context/LanguageContext";
import useStorage from "../hooks/useStorage";

function ClubSelection() {
    const storage = useStorage();
    const [clubs, setClubs] = useState([]);
    const [defaultClub, setDefaultClub] = useState('');
    const [selectedClub, setClub] = useState('');
    useEffect(() => {
      if(!storage) {
        return;
      }
      const _clubs = JSON.parse(storage.getItem('storedClubs'))
      setClubs(_clubs);
      setDefaultClub(_clubs[0].CLUB_CODE);
      const _selected =  storage.getItem('selectedClub') || _clubs[0].CLUB_CODE
      console.log({_selected})
      setClub(_selected);

    }, [storage])
    


  
    const handleChange = e => {
      const selectedValue = e.target.value;
      setClub(selectedValue);
      storage.setItem('selectedClub', selectedValue);
    };
    const ServerUrl = useContext(UrlContext);
    const { /* language, */ translations } = useLanguage();
    async function useSelectedClub() {
      console.log({selectedClub})
      if(!selectedClub) {
        return;
      }
          return await fetch(`${ServerUrl}/SelectedClub`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({SelectedClub: selectedClub})
          })
            .then(dataClub => dataClub.json());
      };
  /* useEffect(() => {
    useSelectedClub().then(x => {
      console.log({x})
    }).catch(() => console.log('down'))
   })*/
  return (
     <div>
        <label className="ClubSelection">
            <h3>{translations.ClubSelection}: </h3> 
            <select value={selectedClub} onChange={handleChange}>
              {clubs.map(item => {
                  return (<option key={item.CLUB_CODE} value={item.CLUB_CODE}> {item.NAME} </option>);
              })}
            </select>
          </label>
    </div>
  )
}

export default ClubSelection
import React from 'react';

const UrlContext = React.createContext();

const UrlProvider = ({ children }) => {
 const ServerUrl = "https://eurofitness.info:5000"; 
 // const ServerUrl = "http://localhost:5000";


  return (
    <UrlContext.Provider value={ServerUrl}>
      {children}
    </UrlContext.Provider>
  );
};

export { UrlContext, UrlProvider };